div.JS9Panner,
div.JS9Magnifier,
div.JS9Menubar,
div.JS9Console,
div.JS9Info {
  /* relative pos top/left for container, Flanagan's JavaScript, p 357 */
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  padding: 0px;
  background-color: #003247;
  border-color: #ed1b2f;
  border-width: 1px;
}

::ng-deep img.JS9MenubarImage {
  background-color: white;
}

div.JS9Toolbar {
  /* relative pos top/left for container, Flanagan's JavaScript, p 357 */
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  padding: 0px;
  background-color: #003247;
  border-color: #ed1b2f;
  border-width: 1px;
}

::ng-deep div.JS9ToolbarDiv {
  background-color: white;
}

::ng-deep .JS9ToolbarContainer {
  border-top: 2px solid #960136;
}

::ng-deep .JS9ToolbarImageButton {
  border-color: #960136;
  border-width: 1px;
  border-style: dotted;
  box-shadow: -5px 8px 16px 0px rgba(0, 0, 0, 1);
}

::ng-deep .JS9Button {
  background-color: #335e6f;
  color: white;
  box-shadow: -5px 8px 16px 0px rgba(0, 0, 0, 1);
  font-size: 14px;
}

::ng-deep .JS9ColorbarContainer {
  background-color: white;
  max-height: 40px;
}

::ng-deep .JS9ColorbarCanvas {
  background-color: #003247;
  height: 10px;
  max-height: 10px;
  width: 100%;
}

::ng-deep .JS9ColorbarTextCanvas {
  margin-top: -4px;
}

::ng-deep .upper-container {
  background-color: #335e6f;
  //background-image: url("./assets/esa_white.png");
  background-position: center;
  background-repeat: no-repeat;
}

::ng-deep .context-menu-root {
  background-color: #960136;
  border-color: #960136;
  border-width: 1px;
  // font-family: "NotesEsa";
  font-size: 14px;
}

::ng-deep .context-menu-item.context-menu-disabled {
  //background-color: #f1666a;
  color: silver;
}

::ng-deep .context-menu-item {
  background-color: #335e6f;
  color: white;
}

::ng-deep .context-menu-submenu {
  background-color: #335e6f;
  color: white;
}

::ng-deep .drag-handle {
  // font-family: "NotesEsa";
  background-color: #960136;
  color: white;
}

::ng-deep .drag-contentarea {
  // font-family: "NotesEsa";
  background-color: white;
  // color: white;
}

::ng-deep div.JS9KeyboardContainer {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9KeyboardItem {
  background-color: #003247;
}

::ng-deep div.JS9KeyboardHeader {
  background-color: #003247;
}

::ng-deep div.JS9KeyboardText {
  background-color: #003247;
}

::ng-deep div.JS9KeyboardAction {
  background-color: #003247;
}

::ng-deep .js9AnalysisForm {
  background-color: white;
}

::ng-deep .JS9Archive-form {
  background-color: #003247;
  color: white;
}

::ng-deep div.FITSBinning {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9Blend {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9Blink {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9BlinkHeader {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9Cmaps {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9CmapsImage {
  background-color: #003247;
}

::ng-deep div.JS9Cube {
  background-color: #003247;
  color: white;
}

::ng-deep div.ImExamContours {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9Mef {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9MefExtensionInactive {
  background-color: lightgray !important;
}

::ng-deep div.JS9MefExtension {
  background-color: #003247;
  border-color: white;
}

::ng-deep div.JS9BlendHeader {
  background-color: #003247;
}

::ng-deep div.JS9BlendImage {
  background-color: #003247;
}

::ng-deep div.JS9BlendImageContainer {
  background-color: #003247;
}

::ng-deep div.JS9BlinkImage {
  background-color: #003247;
}

::ng-deep div.JS9BlinkImageContainer {
  background-color: #003247;
}

::ng-deep div.JS9Filters {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9Imarith {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9CmapsHeader {
  background-color: #003247;
}

::ng-deep div.JS9CmapsImageContainer {
  background-color: #003247;
}

::ng-deep div.JS9SyncUI {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9SyncUIHeader {
  background-color: #003247;
}

::ng-deep div.JS9SyncUIOpCheck {
  background-color: #003247;
}

::ng-deep span.JS9SyncUIOp {
  background-color: #003247;
}

::ng-deep span.JS9SyncUIOpts {
  background-color: #003247;
}

::ng-deep div.JS9SyncUIOptsRow {
  background-color: #003247;
}

::ng-deep div.JS9SyncUIOptsCheck {
  background-color: #003247;
}

::ng-deep div.JS9SyncUIOpt {
  background-color: #003247;
}

::ng-deep div.JS9DivsDivContainer {
  background-color: #003247;
}

::ng-deep div.JS9Layers {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9LayersHeader {
  background-color: #003247;
}

::ng-deep div.JS9LayersLayer {
  background-color: #003247;
}

::ng-deep div.JS9Separate {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9SeparateHeader {
  background-color: #003247;
}

::ng-deep div.JS9SeparateImage {
  background-color: #003247;
}

::ng-deep div.JS9MouseTouch {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9MouseTouchHeader {
  background-color: #003247;
}

::ng-deep div.JS9MouseTouchText {
  background-color: #003247;
}

::ng-deep div.JS9MouseTouchAction {
  background-color: #003247;
}

::ng-deep div.JS9MouseTouchFooter {
  background-color: #003247;
}

::ng-deep div.ImExamPxTabl {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9MefHeader {
  background-color: #960136;
}

::ng-deep div.JS9Divs {
  background-color: #003247;
  color: white;
}

::ng-deep div.JS9DivsHeader {
  background-color: #003247;
  border-color: white;
}

::ng-deep div.JS9DivsDiv {
  background-color: #003247;
  border-color: white;
}

::ng-deep a {
  font-weight: bold;
}

// ::ng-deep .xAxis,
// .x1Axis, .yAxis, .y1Axis {
//   color: white;
// }

::ng-deep .ImExamHistogram {
  background-color: white;
  color: #003247;
}
::ng-deep .ImExamEncEnergy {
  background-color: white;
  color: #003247;
}
::ng-deep .ImExamRadialProj {
  background-color: white;
  color: #003247;
}
::ng-deep .ImExamRegionStats {
  background-color: white;
  color: #003247;
}
::ng-deep .ImExamXProj {
  background-color: white;
  color: #003247;
}
::ng-deep .ImExamYProj {
  background-color: white;
  color: #003247;
}
::ng-deep .JS9Info {
  background-color: white;
  color: #003247;
}

::ng-deep .JS9ScalePlot {
  background-color: white;
  color: #003247;
}

::ng-deep .helpContainer {
  background-color: white;
}
::ng-deep .lightWinCloseForm {
  color: white;
  background-color: #003247;
}

::ng-deep .cdk-overlay-container {
  z-index: 100;
}
