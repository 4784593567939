@import './variables';

.labelHeader {
  font-family: $font-family-title-bold;
  font-size: 1rem;
  font-weight: 500;
  color: $dark-color-2;
}

/** Auto Completer **/
.completer-dropdown {
  overflow-y: scroll;
  max-height: 8rem;
  border-color: #ececec;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  width: 100%;
  padding: 6px;
  cursor: pointer;
  position: fixed;
}

.completer-input {
  border: 0;
  width: 100%;
}

// Split areas background
.as-split-are,
.as-horizontal {
  background-color: $light-color-2;
}

ul {
  padding-left: 0 !important;
  margin-bottom: 0px !important;
}
// Other settings
// avoid link hightlights in Chrome (very annoying)
:focus {
  outline: none;
}

// ESA button style
.button-esa {
  display: inline-block !important;
  text-transform: uppercase !important;
  color: $dark-color-2 !important;
  padding: 5px 10px !important;
  background-color: transparent !important;
  border: 2px solid $dark-color-2 !important;
  border-radius: 4px !important;
  transition: all 0.25s ease !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.08rem !important;
  font-weight: 600 !important;
  line-height: 25px !important;
}

.button-esa:hover,
.button-esa:active,
.button-esa:focus,
.button-icon-esa:hover,
.button-icon-esa:active,
.button-icon-esa:focus,
.button-icon-toolbar-esa:hover,
.button-icon-toolbar-esa:active,
.button-icon-toolbar-esa:focus {
  background-color: $dark-color-2 !important;
  color: $light-color !important;
}

.button-icon-table-esa:hover,
.button-icon-table-esa:active,
.button-icon-table-esa:focus {
  background-color: $light-color !important;
  color: $dark-color-2 !important;
}

// ESA button style
.button-icon-esa {
  display: inline-flex !important;
  text-transform: uppercase !important;
  color: $dark-color-2 !important;
  font-weight: bold;
  background-color: transparent !important;
  border: 2px solid $dark-color-2 !important;
  border-radius: 4px !important;
  transition: all 0.25s ease !important;
  align-items: center;
  padding: 10px !important;
}

.button-icon-toolbar-esa {
  display: inline-flex !important;
  text-transform: uppercase !important;
  color: $dark-color-2 !important;
  font-weight: bold;
  background-color: transparent !important;
  border: 2px solid $dark-color-2 !important;
  border-radius: 4px !important;
  transition: all 0.25s ease !important;
  align-items: center;
  padding: 3px !important;
  height: 35px !important;
  width: 35px !important;
}

.button-icon-table-esa {
  display: inline-flex !important;
  text-transform: uppercase !important;
  color: $light-color !important;
  font-weight: bold;
  background-color: $dark-color-2 !important;
  border: 2px solid $light-color !important;
  border-radius: 4px !important;
  transition: all 0.25s ease !important;
  align-items: center;
  padding: 3px !important;
  height: 35px !important;
  width: 35px !important;
}

.button-esa:disabled,
.button-icon-esa:disabled,
.button-icon-toolbar-esa:disabled {
  border: 2px solid $disabled-color !important;
  color: $disabled-color !important;
}

.button-icon-table-esa:disabled {
  border: 2px solid $disabled-color-2 !important;
  color: $disabled-color-2 !important;
}

.same-row {
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    margin: 0 0.5rem;
  }
  padding-bottom: 0.5rem;
}

.ngx-toastr {
  background-color: $dark-color !important;
  background-image: none !important;
  width: 256px !important;
  padding: 0px !important;
}

.toast-container {
  border-radius: 22px !important;
  box-shadow: -5px 8px 16px 0px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 2px !important;
  background-image: none !important;
  animation-duration: 0.1s;
}

.toast-container .toast {
  margin: 2px !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  background-color: $dark-color !important;
  background-image: none !important;
}

.toast-info {
  background-image: none !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
}

.close-button-card {
  position: absolute !important;
}
.close-button {
  float: right !important;
  top: -4px !important;
  right: -4px !important;
}

.spectra-header-title {
  padding: 5px;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

// Custom ScroolBar
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: $dark-color;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: $dark-color;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $dark-color;
}

// Angular Spinner
.esdc-spinner {
  z-index: 99999 !important;
}

.dialog-title {
  color: $border-color;
}

.image-tooltip {
  width: 100%;
  padding: 5px;
}

.custom-gutter {
  width: 18px;
}

.custom-horizontal-gutter {
  height: 18px;
}

.gutter-button {
  font-size: 20px !important;
  font-weight: bold;
  color: $dark-color;
}
.action-gutter-button {
  cursor: pointer;
}

.resize-horizontal-gutter-button {
  cursor: row-resize !important;
}

.resize-vertical-gutter-button {
  cursor: col-resize !important;
}

.rotated-button {
  transform: rotate(90deg);
}

.as-split-gutter {
  background-color: $light-color !important;
  cursor: default !important;
}

.single-filter-variable {
  color: #ff7f0e !important;
}

.multi-filter-variable {
  color: green !important;
}
