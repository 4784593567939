// Material
@use '@angular/material' as mat;

@include mat.core();

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$indigo-palette),
      accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
      warn: mat.define-palette(mat.$red-palette)
    )
  )
);

// Apply the light theme by default
@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);

// Bootstrap
@import 'bootstrap/dist/css/bootstrap.min.css';

// Material ui
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Material icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Toast
// @import '~ngx-toastr/toastr.css';

// themes - our custom or/and out of the box themes
@import 'themes';

// Variables
@import 'variables';

html,
body {
  background-color: $light-color-2;
}

a {
  //text-decoration: none;
  color: inherit;
}

.cc-btn {
  text-decoration: none !important;
}
