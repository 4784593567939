//Global variables
$font-family-title: NotesESAregular;
$font-family-title-bold: NotesESAbold;

$dark-color: #003247;
$dark-color-2: #335e6f;
$dark-color-3: #0b1d26;
$science-color: #ed1b2f;
$light-color: white;
$border-color: #960136;
$light-color-2: #e8e8e4;
$color-primary-default: #0076a5;
$backgound-color: white;
$disabled-color: lightgray;
$disabled-color-2: #003247;

// Some sized
$app-header-height: 64px;
$app-footer-height: 64px;
$app-per-banner-height: 25px;
