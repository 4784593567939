// Application theme
@import 'custom-theme.scss';

// Tabulator
@import './../../../../../node_modules/esdc-table/styles/custom-tabulator.scss';

// JS9 theme
@import 'js9-theme.scss';

// loading progress bar theme
@import 'pace.theme';

.carousel-indicators {
  left: 0px !important;
  transform: translate(0%) !important;
}
